import * as React from "react"
import Layout from "../../components/layout"
import PageHeader from "../../components/page-header"
import TestimonialSlider from "../../components/testimonial-slider"
import { StaticImage } from "gatsby-plugin-image"

const SofaChairPage = () => {
  return (
    <Layout>
      <PageHeader title="Sofa & Chair Collection" breadcrumbs="Home /  Sofa & Chair Collection" />
      <main className={'mx-auto max-w-screen-xl w-full px-6 py-8 lg:py-16 relative'}>
        <div className={'lg:grid lg:grid-cols-2 lg:gap-16'}>
          <div>
            <div className={'lg:sticky lg:top-16 space-y-8'}>
              <StaticImage
                src="../../images/sofa-chair-1.jpeg"
                alt=""
                layout="fullWidth"
              />
              <StaticImage
                src="../../images/sofa-chair-2.jpeg"
                alt=""
                layout="fullWidth"
              />
            </div>
          </div>
          <div>
            <div className={'lg:sticky lg:top-16'}>
              <div className={'prose-lg'}>
                <h2>Beautifully Bespoke</h2>
                <p>We are a long time supplier and stockist of Coachhouse furniture. However, in order to create a truly individual look we often design and create bespoke pieces to complement the design scheme.</p>
                <p>We work closely with a number of trusted craftsmen, to create high quality pieces of furniture, in individual designs and materials tailored to the scheme.</p>
                <p>Our commitment to quality extends to every area of the design and production process; sourcing only the finest quality materials for our bespoke furniture creations.</p>
                <p>We are able to take bespoke furniture from initial concept to completion and delivery in less than 6 weeks</p>
                <p>All bespoke furniture is handmade in Northern Ireland.</p>
                <p>Not only do we create bespoke pieces from scratch, we also bring old pieces back to life with our comprehensive French Polishing, refurbishment and reupholstery service.</p>
                <p>Typical design briefs can range from traditional &amp; contemporary styles of sofas, armchairs, sofa beds, daybeds, dining chairs, footstools, headboards &amp; ottomans to bespoke one off concepts.</p>
                <p>Don’t take our word for it.</p>
              </div>
              <TestimonialSlider />
            </div>
          </div>
        </div>
      </main>
      <main className={'mx-auto max-w-screen-xl w-full px-6 py-8 lg:py-16 relative'}>
        <div className={'space-y-8 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8'}>
          <StaticImage
            src="../../images/sofa-chair-3.jpeg"
            alt=""
            layout="fullWidth"
          />
          <StaticImage
            src="../../images/sofa-chair-4.jpeg"
            alt=""
            layout="fullWidth"
          />
          <StaticImage
            src="../../images/sofa-chair-5.jpeg"
            alt=""
            layout="fullWidth"
          />
        </div>
      </main>
    </Layout>
  )
}

export default SofaChairPage

export const Head = () => (
    <>
    <title>Interior Design - Jo Andrews Interiors</title>
    <meta name="description" content="We deliver inspirational interiors that reflect our clients’ personalities and fit with how they wants to live. We pride ourselves on designing practical solutions for whatever the project requires." />
    </>
)
