import React from "react"
import AliceCarousel from 'react-alice-carousel'

const items = [
  <div className={'select-none'}>
    <blockquote className={'text-xl leading-8 italic mb-4 pr-8'}>“Jo designed and project managed our remodel last year. She listened to our ideas, offered great suggestions and worked within our budget. We are so happy with the results.”</blockquote>
    <p><strong>Andrea &amp; Jonathan Finlay<br/>Belfast</strong></p>
  </div>,
  <div className={'select-none'}>
    <blockquote className={'text-xl leading-8 italic mb-4 pr-8'}>“Jo has undertaken several projects for me, most recently the interior design and refurnishing of my new home. She has a wonderful sense of texture and colour, an eye for the quirky and unusual and has access to great craftsmanship. I couldn’t recommend her highly enough.”</blockquote>
    <p><strong>Trish Fraser<br/>Cultra</strong></p>
  </div>,
  <div className={'select-none'}>
    <blockquote className={'text-xl leading-8 italic mb-4 pr-8'}>“Jo listened to our ideas, determined our style and tastes and came up with a complete solution which included furniture, fabric, paint, artwork, kitchen/bathroom materials and hardware. The end result was fantastic.”</blockquote>
    <p><strong>Jaime Steele<br/>Belfast</strong></p>
  </div>
]

export default function TestimonialSlider() {
  return (
    <>
      <p className={'text-lg mt-16 mb-4 text-gray-600'}>Here’s what our clients have to say:</p>
      <div className={'relative flex max-w-full'}>
        <AliceCarousel
          autoPlay
          autoPlayStrategy="all"
          autoPlayInterval={5000}
          animationDuration={1000}
          infinite
          mouseTracking
          disableButtonsControls
          items={items}
          autoWidth
        />
      </div>
    </>
  )
}
